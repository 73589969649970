import M1 from '../../assets/M1.svg';
import U1 from '../../assets/U1.svg';
import N1 from '../../assets/N1.svg';
import U2 from '../../assets/U2.svg';
import N2 from '../../assets/N2.svg';
import O1 from '../../assets/O1.svg';
import corazon1 from '../../assets/corazon1.svg';
import corazon2 from '../../assets/corazon2.svg';
import corazon3 from '../../assets/corazon3.svg';
import corazon4 from '../../assets/corazon4.svg';
import  '../../styles/UnderConstruction.css';
function ImagenMununo() {
    return( 
        <div className ='div-imagen'>
            <div className = 'div-corazones'>
                <div className='div-corazon1'>
                    <img className = 'corazon' src={corazon1} alt="corazon-1" />
                </div>
                <div className='div-corazon2' >
                    <img className = 'corazon' src={corazon2} alt="corazon-2" />  
                </div>
                <div className='div-corazon3' >
                    <img className = 'corazon' src={corazon3} alt="corazon3" /> 
                </div>
                <div className='div-corazon4' >
                    <img className = 'corazon' src={corazon4} alt="corazon4" />
                </div>
            </div>
            <div className='div-letras'>
                <div className='div-M1'>  
                    <img className='letra'src={M1} alt="M" />
                </div>
                <div className='div-U1'>
                    <img className='letra' src={U1} alt="U"/>
                </div>     
                <div className='div-N1'> 
                    <img className='letra' src={N1} alt="N"/>
                </div> 
                <div className='div-U2' >
                    <img className='letra' src={U2} alt="U" />
                </div>
                <div className='div-N2' >
                    <img className='letra' src={N2} alt="N" />
                </div>
                <div className='div-O1' >
                    <img className='letra' src={O1} alt="O" />
                </div>
            </div>
        </div>
    )
}
export default ImagenMununo;