import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './components/App';
import Fondo from './components/under_construction/Fondo'
import ImagenMununo from './components/under_construction/ImagenMununo'
import reportWebVitals from './reportWebVitals';
import UnderConstruction from './components/under_construction/underConstruction';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Fondo/> 
    <ImagenMununo/>
    <UnderConstruction/>
  </React.StrictMode>
);

reportWebVitals();
