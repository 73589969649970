function Fondo() {
 return(  
    <div className="fondo">
            <div className = 'estamos'>¡Estamos en plena construcción de nuestro sitio web! <br/> ¡No podríamos estar más emocionados por lo que está por venir!</div>
            <div className = 'linea-gris'></div>
            <div className = 'infomununo'>info@mununo.com</div>
            <div className = 'preparate' >¡Prepárate para descubrir algo increíble muy pronto!</div>
    </div>
)
}
export default Fondo;